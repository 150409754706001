@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

html,body {
  width: 100vw;
  height:100%;
}

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin:0;
  overflow-x: hidden;
}

#root {
  width: 100%;  
  position: relative;
  min-height: 100vh;
}

.mLogo {
  width:240px;
}


