/* Reusable vars */
@import './variables';
$navFontSize: 14px;
$mobileNavBorderColor:#a8a8a8;

a.hamburgerIcon {
  display:none;
  .topNavHamburger {
    font-size: 24px;
    cursor: pointer;
  }
}

.topnav {
    overflow: hidden;
      /* Style the links inside the navigation bar */
    a {
        float: left;
        display: block;
        color: #4e4e4e;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        font-size: $navFontSize;
        font-weight:500;
    }
    .topNavChevronDown {
      font-size: 9px;
      padding-left:2px;
    }
    a.activeTopLink {
        border-bottom:4px solid $meteorcommRed; 
        color: $meteorcommRed;
    }
    /* Dropdown container */
    .dropdown {
      float: left;
      overflow: hidden;
    }
    /* dropdown content (hidden by default) */
    .dropdown-content {
      display: none;
      position: absolute;
      top:108px;
      margin-left:14px;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1000000;
      /* inside the dropdown */
      a {
        float: none;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
      }
    }
    .dropdown-content a:hover {
      background-color: $meteorcommRed;
      color:#fff;
    }
  }
  
  @media screen and (min-width: $minDesktopWidth) {
    /* Show the dropdown menu on hover */
    .dropdown:hover .dropdown-content {
      display: block;
    }
  }

  /* (display the links vertically instead of horizontally) */
  @media screen and (max-width: $maxMobileWidth) {    
    .dropdown .topNavChevronDown {
      display:none;
    }
    .logoNavBox {
      .topNavBox {
        padding-left:20px;
      }
      .mLogo {
        width:180px;
      }
      .App-title {
        font-size:12px;
      }
    }
    a.hamburgerIcon {
      display:block;
      margin-top:10px;
    }
    .topnav a.activeTopLink {
        border-bottom:0px; 
        color: $meteorcommRed;
    }
    /* .responsive class is added when the user clicks on hamburger. */
    .topnav.responsive {
      z-index:999999;
      background-color:#fff;
      position:absolute;
      top:110px;
      left:110px;
      border:1px solid $mobileNavBorderColor;
      overflow-y:auto;
      a {
        float: none;
        display: block;
        text-align: left;
      }
      .dropdown .dropbtn {
        display: block;
        width: 100%;
        text-align: left;
      }
      .dropdown {
        float: none;
        .dropdown-content {
          display:block;
          position: relative;
          top:0
        }
      }
    }

    .topnav {
      a, .dropdown .dropbtn {
        display: none;
      }
      .dropdown-content {
        box-shadow: inherit;
        a {
          margin-right:14px;
        }
      }
    }
  }