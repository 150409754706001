@import './variables';
$innerMargin: 44px;

/* Top element starting mainapp.scss */
.main {
  max-width: 1298px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 24px;
  padding: 0 24px 90px 24px;
  font-size: 14px;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 90px;
  max-width: 1298px;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;

  .mLogo.footerLogo {
    width: 140px;
  }

  .footerLinkedIn {
    font-size: 18px;
    color: rgb(109, 109, 109);
  }

  .footerLinkedIn:hover {
    color: #0a66c2;
  }

  .innerFooter {
    // border-top:3px solid $meteorcommRed;
    margin-left: $innerMargin;
    margin-right: $innerMargin;
    padding: 0 38px 8px 38px;
    height: 100%;

    .footerDivide {
      color: #9b9b9b;
    }
  }
}


/* product/page title */
.App-title {
  font-size: 18px;
  letter-spacing: 1.4px;
  margin-top: -4px;
  font-weight: 500;
  color: #666;
}

/* outer element wrapping nav */
.topNavBox {
  padding-left: 40px;
  width: calc(100% - 300px);
}

.logoNavBox {
  width: 100%;
}

/* Top bar */
.loginBar {
  $loginBarFontSize: 12px;
  margin-right: $innerMargin;
  font-size: $loginBarFontSize;

  .contactButton {
    font-size: $loginBarFontSize;
    width: 64px;
    min-width: 64px;
    padding: 0;
    height: 26px;
    margin-left: 12px;
  }
}

/* main page image and title*/
.pageFlare {
  position: relative;

  .imgBox {
    margin-left: $innerMargin;
    margin-right: $innerMargin;

    img {
      width: 100%;
    }
  }

  .homeTitleBox {
    background-color: $meteorcommRed;
    color: #fff;
    min-width: 280px;
    max-width: 60%;
    font-size: 3vw;
    font-weight: 700;
    text-align: center;
    padding: 40px;
    bottom: -12px;
    z-index: 99999;
    position: absolute;
    display: inline-block;
  }
}

/* Responsive queries */
@media screen and (min-width: 1280px) {
  .pageFlare {
    .homeTitleBox {
      font-size: 44px;
    }
  }
}

@media screen and (max-width: 720px) {
  .pageFlare {
    .homeTitleBox {
      padding: 30px;
      margin-top: -55px;
      max-width: 240px;
      min-width: 180px;
      font-size: 2.4vw;
    }
  }
  .footer {
    font-size: 10px;

    .mLogo.footerLogo {
      width: 100px;
    }
    .footerLinkedIn {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: $maxMobileWidth) {
  .pdfDescText {
    max-width: inherit;
  }
}

.pageBody {
  margin-top: $innerMargin;
  padding-left: $innerMargin;
  padding-right: $innerMargin;
}

.homagePageContent {
  max-width: 700px;

  p {
    text-align: justify;
  }

  margin-left:auto;
  margin-right:auto;
}

/* Legal Terms */
.legalTermsBox {
  color: #000;
  padding: 10px;
  overflow-y: auto;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;

  &+div {
    padding-top: 18px;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e7e7e7;
    border: 1px solid #cacaca;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #375987;
  }
}

.legalTermsButtons {
  margin-left: auto;
  margin-right: auto;
}

.partsListFiles {
  margin-bottom: 32px;
}

/* Fluent Details List Heading */
.detailsHeading {
  font-weight: 600;
  font-size: 18px;
}

.appWrap {
  display: none;
}

.appWrap.loadOverlay {
  display: block;
  position: absolute;
  z-index: 99999999999999;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.12);
}

.appWrap .loadMessage {
  padding: 28px;
  background-color: rgba(0, 0, 0, 0.8);
  width: auto;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  label {
    color: #ffffff;
    font-weight: normal;
  }
}

/* CSS loading animation */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #ffffff;
      margin: -4px 0 0 -4px;
    }

    &:nth-child(1) {
      animation-delay: -0.036s;
    }

    &:nth-child(1):after {
      top: 63px;
      left: 63px;
    }

    &:nth-child(2) {
      animation-delay: -0.072s;
    }

    &:nth-child(2):after {
      top: 68px;
      left: 56px;
    }

    &:nth-child(3) {
      animation-delay: -0.108s;
    }

    &:nth-child(3):after {
      top: 71px;
      left: 48px;
    }

    &:nth-child(4) {
      animation-delay: -0.144s;
    }

    &:nth-child(4):after {
      top: 72px;
      left: 40px;
    }

    &:nth-child(5) {
      animation-delay: -0.18s;
    }

    &:nth-child(5):after {
      top: 71px;
      left: 32px;
    }

    &:nth-child(6) {
      animation-delay: -0.216s;
    }

    &:nth-child(6):after {
      top: 68px;
      left: 24px;
    }

    &:nth-child(7) {
      animation-delay: -0.252s;
    }

    &:nth-child(7):after {
      top: 63px;
      left: 17px;
    }

    &:nth-child(8) {
      animation-delay: -0.288s;
    }

    &:nth-child(8):after {
      top: 56px;
      left: 12px;
    }
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.contactFrame {
  box-shadow: rgb(9 30 66 / 60%) 0px 4px 8px -2px, rgb(9 30 66 / 16%) 0px 0px 0px 1px;
  border: 0 !important;
  max-width: 100%;
  max-height: 350vh;
}

.pdfFrame {
  box-shadow: rgb(9 30 66 / 60%) 0px 4px 8px -2px, rgb(9 30 66 / 16%) 0px 0px 0px 1px;
  width: 100%;
  max-width: 900px;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
}

.pdfDescText {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;

  p {
    text-align: justify;
  }
}


.faqBody {
  width: 100%;

  .faqPanel {
    width: 100%;
    max-width: 960px;
  }
}

.commandBarStyle {
  margin-top: 16px;
  border: 1px solid #e6e6e6;
}

.freqLinksBody {
  margin: 14px 9px;
}

.researchListFiles {
  margin-top: 20px;

  .detailsIconStyle {
    vertical-align: bottom;
  }
}